#loading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin-top: 100px;
  /* justify-content: center; */
  align-items: center;
}

#loading-text {
  font-size: 2rem;
}

/* #loading-quote {
} */

#loading-anim {
  margin-top: 20px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 2px solid #3fcff3;
  background-color: #3fcff3;
  transition: transform 0.5s ease-in;
  animation: loadingAnimation 1s infinite;
}

@keyframes loadingAnimation {
  1% {
    /* transform: rotate(0deg); */
    transform: translate(0px, 0);
  }

  50% {
    /* transform: rotate(360deg); */
    transform: translate(50px, 0);
  }

  99% {
    /* transform: rotate(0deg); */
    transform: translate(-50px, 0);
  }
}
