.overlay {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  /* background-color: #f6f3f3; */
  background-color: #eeeeee;
  overflow-x: hidden;
  transition: 0.5s;
  overflow-y: auto;
}

.overlay--open {
  width: 100%;
  height: 100%;
}

.overlay__closebtn {
  position: absolute;
  text-decoration: none;
  top: 10;
  right: 10;
  font-size: 4rem;
  z-index: 100;
  padding: 0;
  transition: 0.3s;
  color: #818181;
  display: block;
}

.overlay__closebtn:hover,
.overlay__closebtn:focus {
  color: #b1b1b1;
}

.overlay-content {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.overlay-content__btn {
  font-size: 1.8rem;
  cursor: pointer;
  background-color: #2490f4;
  color: #fff;
  text-align: center;
  border-radius: .5rem;
  padding: .2rem .5rem;
}

.overlay-content__btn--chat {
  margin-right: .5rem;
}

.overlay-content__btn--downloadPdf {
  font-size: 1.8rem;
  cursor: pointer;
  text-align: center;
  border-radius: .5rem;
  padding: .5rem .5rem;
  text-decoration: underline;
}

.chat {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  flex: 1 1 50%;
  max-height: '40rem';
  /* margin-top: 1rem; */
  background-color: #f6f3f3;
}

.chat__messages {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
}

.chat__message {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  align-self: flex-start;
  padding: 0.1rem 1rem;
  margin: 0 0rem 1rem 0rem;
  background-color: #fff;
  color: #000;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  max-width: 60%;
}

.chat__message:last-of-type {
  margin-bottom: 0;
}

.chat__message:first-of-type {
  margin-top: 5rem;
}

.chat__message--me {
  align-self: flex-end;
  background-color: #c2dcb4;
}

.chat__message--image {
  padding: 0.1rem;
}

.chat__message-body {
  width: 100%;
  height: 100%;
  /* max-width: 15rem; */
  font-size: 1.6rem;
  /* For Firefox */
  white-space: pre-wrap;
  word-break: break-word;
  /* For Chrome and IE */
  word-wrap: break-word;
}

.chat__message-text {
  padding-top: 1rem;
}

.chat__message-time {
  font-size: 0.9rem !important;
  color: #202124;
  text-align: right;
  padding-bottom: 0.5rem;
  /* For Firefox */
  white-space: pre-wrap;
  /* For Chrome and IE */
  word-wrap: break-word;
}

.chat__image-link {
  display: block;
  text-decoration: none;
  /* 
  The height and width are simply placeholders for chat images while they are loading, 
  so that the chat__messages element is always auto scrolled to the very bottom during initial loading
  of messages ond/or when a new message is sent. 
  */
  /* width: 14rem !important; */
  /* height: 14rem !important; */
  overflow: hidden;
  position: relative;
  padding: 0.8rem;
  cursor: pointer;
  width: 140px;
  margin: 0 auto;
  /* overflow: hidden; */
  /* position: relative; */
  height: 140px;
}

.chat__image-link:hover {
  color: #f1f1f1;
  background-color: #add5ad;
}

.chat__image-link:focus {
  color: #f1f1f1;
}

.chat__image {
  display: block;
  width: 100%;
  height: 100%;
}

.chat__actions {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 1px;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  justify-content: space-between;
}

.chat__message-input {
  resize: none;
  border: 0 none;
  width: 100%;
  /* height: 100%; */
  font-size: 1.6rem;
  /* padding: 0.8rem 0.8rem 0.8rem 0; */
  outline: none;
}

.chat__message-upload {
  margin-left: 0rem;
}

.chat__btn {
  background: #fff;
  color: #2ecc71;
  border: 0 none;
  outline: none;
  cursor: pointer;
  padding: 10px;
}

.backBtn {
  padding: 5px !important;
  margin-right: 10px;
  background-color: #fafafa;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.backBtn:hover {
  background-color: #53ceee;
  color: white;
}

.chat__notification {
  display: flex;
  flex-direction: column;
}

.chat__notification_text {
  margin: 0px;
  background: white;
  border-top: 1px solid #d9d9d9;
}

@media screen and (max-width: 500px) {
  .chat__message-input {
    width: 75%;
  }
}