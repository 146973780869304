#doctorMedia {
  position: relative;
  width: 80%;
  height: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
  margin: auto;
}

.mainWindow {
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}

.smallWindow {
  position: absolute;
  width: 20%;
  bottom: 10%;
  right: 5%;
  border-radius: 15px;
}

#hangup:hover {
  background-color: #aaaaaa79;
  border-radius: 80%;
  padding: 3px;
}

#full-window:hover {
  background-color: #aaaaaa79;
  border-radius: 50%;
  padding: 5px;
}

.navBar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent !important;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  #fullScreen {
    display: block;
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }
  .mainWindow {
    width: 100%;
    height: 90vh;
    object-fit: initial;
  }

  #doctorMedia {
    display: block;
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }

  .mainWindowImage {
    width: 100%;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
  }

  .smallWindowImage {
    position: absolute;
    width: 20%;
    bottom: 10%;
    top: 40%;
    right: 5%;
    border-radius: 15px;
  }
}
